:root {
  --swiper-theme-color: #00ffbb !important;
  --rmsc-main: #00ffbb !important;
}

.geist-spacer {
  display: block;
  width: 1px;
  height: 1px;
  min-width: 1px;
  min-height: 1px;
  margin-left: calc(24px - 1px);
  margin-top: calc(24px - 1px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.swiper-button-prev {
  left: 0px !important;
  top: 0% !important;
  background-color: white !important;
  height: 100% !important;
}
.swiper-button-next {
  right: 0px !important;
  top: 0% !important;
  background-color: white !important;
  height: 100% !important;
}

.swiper-button-disabled{
  color:gray !important
}

 
.animate-charcter {
 
  background-image: linear-gradient(-225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 190px;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: #34d399 0 0 0 1px !important;
  border-color:#34d399 !important;
 
}



@keyframes textclip {
  to {
    background-position: 200% center;
  }
}





/* for  Composer task list and item */
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

ul[data-type="taskList"] p {
  margin: 0;
}

ul[data-type="taskList"] li {
  display: flex;
}

ul[data-type="taskList"] li>label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
  margin-top: 0;
  margin-bottom: 0;
}

ul[data-type="taskList"] li>div {
  margin-top: 0;
  margin-bottom: 0;
}

ul[data-type="taskList"] li>div {
  flex: 1 1 auto;
}




/*  */
.ProseMirror table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.ProseMirror table td,
.ProseMirror table th {
  border: 2px solid #ced4da;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}

.ProseMirror table td>*,
.ProseMirror table th>* {
  margin-bottom: 0;
}

.ProseMirror table th {
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: left;
}

.ProseMirror table .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.ProseMirror table .column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.ProseMirror table p {
  margin: 0;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}



/* Multi select */
.rmsc .dropdown-heading{
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Rc slider */
.rc-slider-rail {
  background-color: #6f6f6f !important;
}
.rc-slider-track {
  background-color: #4D4D4D !important;
}

.rc-slider-handle {
  border-color: white!important;
}

.rc-slider-handle:focus {
  border-color: white!important;
}


 .ProseMirror-selectednode iframe {
   transition: outline 0.15s;
   outline: 6px solid #ece111;
 }