@tailwind utilities;

.scrollbar-none {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.scrollbar-w-1\.5::-webkit-scrollbar {
  @apply w-1.5 h-1.5 !important;
}

.scrollbar-track-gray-100::-webkit-scrollbar-track {
  @apply bg-gray-100 !important;
}

.scrollbar-thumb-gray-300::-webkit-scrollbar-thumb {
  @apply bg-gray-300 !important;
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  @apply rounded !important;
}

.scrollbar-track-rounded::-webkit-scrollbar-track {
  @apply rounded !important;
}

.bg-checkered {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23F0F0F0' d='M0 0h8v8H0zm8 8h8v8H8z'/%3E%3C/svg%3E");
  background-size: 16px 16px;
}

.code-highlight {
  border-radius: 0.25rem;
  padding: 0.125rem 0.1875rem;
  margin: 0 -0.1875rem;
}

.dragging-ew,
.dragging-ew * {
  cursor: ew-resize !important;
  user-select: none !important;
}

.mono-active > div:not(.not-mono) > span {
  color: rgba(255, 255, 255, 0.25);
}

.mono > div > span {
  transition-duration: 0.5s;
  transition-property: background-color, border-color, color, fill, stroke;
}

 /* Typography */

 .h1 {
    @apply   text-3xl sm:text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
    @apply text-3xl font-bold leading-tight;
}
.sm_h3 {
    @apply text-[28px] font-bold leading-tight;
}

.dh3 {
    @apply  text-base md:text-3xl font-bold leading-tight;
}

.h4 {
    @apply text-2xl font-bold leading-snug tracking-tight;
}

/* Buttons */
.btn,
.btn-sm {
    @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn_outline{
  @apply font-medium inline-flex items-center justify-center     rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply   px-1.5 py-2 md:px-2  xl:px-3 text-base  ;
}

.btn-lg,.btn_outline {
  @apply px-5 py-3;
}

.btn-sm {
  @apply px-4 py-1.5;
}

.btn-xs {
  @apply px-2 py-0.5;
}

.btn_primary {
  @apply rounded-md px-4 py-2 shadow bg-primary text-white   hover:bg-transparent hover:text-primary border border-transparent hover:border-primary transition duration-300 flex items-center justify-center
}


.btn_blue_primary {
  @apply rounded-md px-4 py-2 shadow bg-periwinkle_blue text-white   hover:bg-transparent hover:text-periwinkle_blue border border-transparent hover:border-periwinkle_blue transition duration-300 flex items-center justify-center
}

.btn_white_primary {
  @apply rounded-md px-4 py-2 shadow bg-white text-lightGray   hover:bg-transparent hover:text-periwinkle_blue border border-gray_light  transition duration-300 flex items-center justify-center
}

.btn_primary_outline {
  @apply rounded-md px-4 py-2 shadow bg-transparent text-primary   hover:bg-primary hover:text-white border border-primary hover:border-transparent transition duration-300 flex items-center justify-center
}


.editor_btn_primary {
  @apply rounded-md px-2 py-1 shadow bg-[#2b2b2b] text-[#ebebeb]  transition duration-300 flex items-center justify-center
}

.editor_btn_primary_outline {
  @apply rounded-md px-2 py-1 shadow bg-[#6b6b6b]   hover:bg-[#4a4a4a]  text-[#ebebeb]   hover:border-transparent transition duration-300 flex items-center justify-center
}


.editor_translate_btn_primary_outline {
  @apply rounded-md px-1 py-0.5 shadow bg-transparent  hover:bg-primary/5  text-primary border border-primary transition duration-300 flex items-center justify-center text-sm
}
.editor_translate_btn_primary_fill {
  @apply rounded-md px-1 py-0.5 shadow bg-primary     text-white  border border-primary transition duration-300 flex items-center justify-center text-sm
}


  /* Forms */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply bg-white border border-gray-300 focus:border-primary;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply py-3 px-4;
}

.form-input,
.form-textarea {
    @apply placeholder-gray-900;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-gray-800 rounded-sm;
}

.custom_gradient_btn{
     background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
}

.custom_gradient_btn:hover {
  background-position: right center; /* change the direction of the change here */
}


/*  */
.custom-icon-gradient {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: transparent !important;
  background: linear-gradient(to right, rgb(101, 84, 192) 25%, rgb(0, 184, 217) 25%, rgb(0, 184, 217) 50%, rgb(255, 153, 31) 50%, rgb(255, 153, 31) 75%, rgb(222, 53, 11) 75%);
}

input[type="color"] {
  background-color: transparent !important;
    color: transparent !important;
}

/* high-zIndex */
z-high{
  z-index: 100 !important;
}
 



/*  */

